import {ref} from "vue";
import type {AuthResponse, UserResponse} from "~/common/interfaces"; // Импорт типов для ответов от API
import {useAuthApi} from "~/services/useAuthApi"; // Импорт API методов для авторизации
import {useUserProfileStore} from "~/store/user/user_prifile.store"; // Импорт Pinia store для работы с профилем пользователя
import {useUserManagementApi} from "~/services/useUserManagementApi"; // Импорт API методов для управления пользователями

export function useAuthLogics() {
    const {handleAuthUserCheck, userLogin, otpVerify} = useAuthApi(); // Деструктуризация методов из useAuthApi
    const {getUserProfileApi} = useUserManagementApi(); // Деструктуризация метода для получения профиля пользователя из useUserManagementApi
    const authStore = useAuthStore(); // Получение состояния авторизации пользователя
    const isLoading = useIsLoadingStore(); // Получение состояния для отображения загрузки
    const statesStore = useStatesStore(); // Получение общего состояния приложения
    const Attempts = ref(0); // Создание реактивного счётчика попыток авторизации

    // Функция проверки существования пользователя
    const user_verification = async (authType: string, identifier: string) => {
        isLoading.set(true); // Устанавливаем состояние загрузки
        try {
            const response: AuthResponse = await handleAuthUserCheck(authType, identifier); // Вызов API для проверки пользователя
            if (response.user_exists) {
                statesStore.IsUserExist = 1; // Если пользователь существует, устанавливаем соответствующий флаг
            } else {
                statesStore.IsUserExist = 2; // Если пользователя нет, устанавливаем другой флаг
                statesStore.IsWarningNotice = true; // Отображаем предупреждение
                setTimeout(() => {
                    statesStore.IsWarningNotice = false; // Скрываем предупреждение через 3 секунды
                }, 3000);
            }
        } catch (e) {
            statesStore.IsUserExist = 0; // В случае ошибки сбрасываем флаг существования пользователя
            statesStore.IsErrorNotice = true; // Отображаем сообщение об ошибке
            setTimeout(() => {
                statesStore.IsErrorNotice = false; // Скрываем сообщение об ошибке через 3 секунды
            }, 3000);
        } finally {
            isLoading.set(false); // Снимаем состояние загрузки
        }
    };

    // Функция для входа пользователя
    const user_login = async (authType: string, identifier: string, password: string) => {
        isLoading.set(true); // Устанавливаем состояние загрузки
        try {
            const response: AuthResponse = await userLogin(authType, identifier, password); // Вызов API для входа пользователя
            if (response) {
                Attempts.value = 0; // Сбрасываем счётчик попыток при успешной авторизации
                authStore.isAuth = true; // Устанавливаем флаг авторизации
                authStore.Access = response.access; // Сохраняем токен доступа
                authStore.Refresh = response.refresh; // Сохраняем токен обновления
                const userProfile = useUserProfileStore(); // Получаем состояние профиля пользователя
                if (authStore.isAuth) {
                    await getUserProfileApi(); // Получаем 
                }
            } else {
                authStore.clear(); // Очищаем данные авторизации в случае неудачи
            }
        } catch (e) {
            Attempts.value++; // Увеличиваем счётчик попыток в случае ошибки
            if (Attempts.value >= 3) { // Если попыток больше или равно трём
                statesStore.OTPIsVerify = false; // Отключаем проверку OTP
                authStore.clear(); // Очищаем данные авторизации
            } else {
                statesStore.OTPIsVerify = true; // Включаем проверку OTP
                authStore.clear(); // Очищаем данные авторизации
            }
            statesStore.IsErrorNotice = true; // Отображаем сообщение об ошибке
            setTimeout(() => {
                statesStore.IsErrorNotice = false; // Скрываем сообщение об ошибке через 3 секунды
            }, 3000);
        } finally {
            isLoading.set(false); // Снимаем состояние загрузки
        }
    };

    // Функция для отправки OTP (оставлена пустой, для будущей реализации)
    const otp_send = () => {
    };

    // Функция для проверки OTP кода
    const otp_verification = async (authType: string, identifier: string, code: string) => {
        isLoading.set(true); // Устанавливаем состояние загрузки
        try {
            const response: AuthResponse = await otpVerify(authType, identifier, code); // Вызов API для проверки OTP кода
            if (response) {
                statesStore.RequestID = response.extra.request_id; // Сохраняем ID запроса
                statesStore.OTPIsVerify = true; // Устанавливаем флаг успешной проверки OTP
            }
        } catch (e) {
            statesStore.RequestID = ''; // Очищаем ID запроса в случае ошибки
            statesStore.OTPIsVerify = false; // Устанавливаем флаг неудачной проверки OTP
            statesStore.IsErrorNotice = true; // Отображаем сообщение об ошибке
            setTimeout(() => {
                statesStore.IsErrorNotice = false; // Скрываем сообщение об ошибке через 3 секунды
            }, 3000);
        } finally {
            isLoading.set(false); // Снимаем состояние загрузки
        }
    };

    // Возвращаем функции для использования в компонентах
    return {user_verification, otp_verification, user_login, otp_send};
}
import type {AuthResponse} from "~/common/interfaces";
import {$useApiFetch} from "~/composables/useApiFetch";
import {endpoints} from "~/constants/endpoints";


export const useAuthApi = () => {
    return {
        handleAuthUserCheck: async (authType: string, identifier: string) => {
            return await $useApiFetch<AuthResponse>(endpoints.auth.user.check, {
                method: 'GET',
                params: {
                    [authType]: identifier
                }
            })
        },
        userLogin: async (authType: string, identifier: string, password: string) => {
            return await $useApiFetch<AuthResponse>(endpoints.auth.user.login, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    [authType]: identifier,
                    password,
                })
            })
        },
        otpVerify: async (authType: string, identifier: string, code: string) => {
            return await $useApiFetch<AuthResponse>(endpoints.auth.otp.verify, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    [authType]: identifier,
                    code
                })
            })
        },
        otpSend: async (authType: string, identifier: string) => {
            return await $useApiFetch<AuthResponse>(endpoints.auth.otp.send, {
                method: 'POST',
                params: {
                    [authType]: identifier
                }
            })
        },
        tokenRefresh: async (refresh: string) => { //TODO: Воткнуть рефреш
            return await $useApiFetch<AuthResponse>(endpoints.auth.token.refresh, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "refresh": refresh
                })
            })
        },
    }
}
